import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '../redux/modules/frontPage';
import useSelector from '../redux/typedHooks';

// Get a document with the given slug
export const useFrontPage = (token?: string, abTestWeight?: number) => {
  const returnValue = useSelector((state) => state.frontPage) || {
    data: undefined,
    meta: { isUpdating: undefined, isLoggedin: undefined, fetchedAt: undefined },
    error: undefined,
  };
  const {
    meta: { fetchedAt, isUpdating, isLoggedin },
    error,
  } = returnValue;

  const dispatch = useDispatch();

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    const weight = abTestWeight || typeof window !== 'undefined' ? window.__AB_TEST_WEIGHT__ : undefined;
    const now = new Date().getTime();
    const age = (fetchedAt && now - new Date(fetchedAt).getTime()) || 3600000;
    if (!error && ((!fetchedAt && !isUpdating) || age > 1800000 || (!isLoggedin && typeof token === 'string'))) {
      dispatch(actions.getFrontPage.request(undefined, { token, abTestWeight: weight }));
    }
  }, [token, dispatch, fetchedAt, isLoggedin, isUpdating, error, abTestWeight]);

  return returnValue;
};
